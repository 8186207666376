/* website: 3268-parkavenuevolvo1
 * created at 2021-01-22 15:47 by fbbreard
 */

// Import all makes and organization styles files
@import "../templates/makes/volvo.scss";
@import "../templates/groups/parkavenue.scss";
@import "../utils/icons.scss";

.widget-sr{
    &.dealer__park-avenue-volvo-autos-brossard{
        .TransactionActionDetails--wrapper .sr-button-outline-1{
            padding: 15px 14px !important;
        }
        .vehicle-cta-btn.sr-button-2{
            background: #2b7bcd;
        }
    }
  }